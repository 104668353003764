// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

.ticker__content {
    will-change: auto;
    color: transparent;
    font-size: xx-large;
    padding: 10px 0 10px 0;
    display: inline-block;
    animation: ticker 80s linear infinite;
    -webkit-text-stroke: 4px white;
    font-size: 6rem;
    font-style: italic;
    font-weight: 800;
}

.ticker__container:nth-child(2) {
    animation-delay: 40s;
}

.ticker__container {
    content-visibility: auto;
    width: 100%;
    min-height: 200px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

@keyframes ticker {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media only screen and (max-width: 940px) {
    .ticker__content {
        font-size: 4rem;
        -webkit-text-stroke: 2px white;
    }
    .ticker__container {
        min-height: 120px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/landing/components/ticker/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;IACrB,qCAAqC;IACrC,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,wBAAwB;IACxB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI;QACI,eAAe;QACf,8BAA8B;IAClC;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".clearfix::after {\n    content: \"\";\n    display: block;\n    clear: both;\n}\n\n.ticker__content {\n    will-change: auto;\n    color: transparent;\n    font-size: xx-large;\n    padding: 10px 0 10px 0;\n    display: inline-block;\n    animation: ticker 80s linear infinite;\n    -webkit-text-stroke: 4px white;\n    font-size: 6rem;\n    font-style: italic;\n    font-weight: 800;\n}\n\n.ticker__container:nth-child(2) {\n    animation-delay: 40s;\n}\n\n.ticker__container {\n    content-visibility: auto;\n    width: 100%;\n    min-height: 200px;\n    overflow: hidden;\n    white-space: nowrap;\n    position: relative;\n}\n\n@keyframes ticker {\n    0% {\n        transform: translateX(0);\n    }\n    100% {\n        transform: translateX(-100%);\n    }\n}\n\n@media only screen and (max-width: 940px) {\n    .ticker__content {\n        font-size: 4rem;\n        -webkit-text-stroke: 2px white;\n    }\n    .ticker__container {\n        min-height: 120px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
